import { datadogRum } from "@datadog/browser-rum";
import { Environments } from "./constants";
import { filterEvents } from "./eventFiltering";
import { identifyEnvironment } from "./identifyEnv";

export type InitOptions = {
  /**
   * Taken from Datadog. This will be displayed in plaintext in the browser so it should
   * not be considered a secret.
   */
  applicationId: string;
  /**
   * Taken from Datadog. This will be displayed in plaintext in the browser so it should
   * not be considered a secret.
   */
  clientToken: string;
  /**
   * `serviceName` should be set to undefined to avoid a single session being tagged
   * with multiple services. For example, the login application is required in all
   * sessions but is a gateway to several other apps.
   */
  serviceName: string | undefined;
  /**
   * The commit hash of the consuming application. Typically this is just
   * `__JSQ_COMMIT_HASH__` which is then replaced at build time by Webpack.
   */
  version: string;
  /**
   * An override for local dev to force the library to init even when it wouldn't otherwise.
   */
  localDevInitOverride?: boolean;
};

const throttledEnvs: readonly string[] = [
  Environments.production,
  Environments.prodops,
  Environments.demo,
  Environments.cit,
];

const unObservedEnvs: readonly string[] = [Environments.beta, Environments.staging];

/**
 * This will initialize Datadog RUM with the passed in parameters.
 * The environment will be automatically identified and RUM will be initialized
 * based on the environment.
 */
export function init(options: InitOptions) {
  // never init datadog rum in tests
  if (process.env.NODE_ENV === "test") {
    return;
  }

  // don't init if already initialized
  if (window.__jsqDDRumLoaded__) {
    return;
  }
  window.__jsqDDRumLoaded__ = true;

  const env = identifyEnvironment();

  // don't init in beta or staging
  if (unObservedEnvs.includes(env)) {
    return;
  }

  // don't init in local env unless debug flag is set
  if (env === Environments.development) {
    const isDebugMode = process.env.DEBUG?.includes("@jsq/observability") ?? false;
    if (!isDebugMode && !options.localDevInitOverride) {
      return;
    }
  }

  // sample at 100% in non-prod environments
  const samplingRate = throttledEnvs.includes(env) ? 20 : 100;

  datadogRum.init({
    silentMultipleInit: true,
    applicationId: options.applicationId,
    clientToken: options.clientToken,
    service: options.serviceName,
    env,
    version: options.version,
    sessionSampleRate: samplingRate,
    // all outgoing requests in sampled sessions will be tracked
    traceSampleRate: 100,
    // no session replays
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    compressIntakeRequests: true,
    allowedTracingUrls: [
      (url) => new URL(url).hostname.endsWith(".junipersquare.com"),
      (url) => new URL(url).hostname.endsWith(".junipersquare.us"),
    ],
    beforeSend: filterEvents,
  });
}

// export additional functions from the RUM SDK
export const {
  addAction,
  addError,
  addTiming,
  setUser,
  clearUser,
  setUserProperty,
  removeUserProperty,
  setGlobalContextProperty,
  removeGlobalContextProperty,
  startView,
} = datadogRum;
